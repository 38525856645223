import { createRouter, createWebHistory } from 'vue-router'
const HomeView = () => import('../views/HomeView.vue')
const WordQuizTop = () => import('../views/WordQuizTop.vue')
const WordQuiz = () => import('../views/WordQuiz.vue')
const VerbQuizTop = () => import('../views/VerbQuizTop.vue')
const VerbQuiz = () => import('../views/VerbQuiz.vue')
const ConjugationQuizTop = () => import('../views/ConjugationQuizTop.vue')
const ConjugationQuiz = () => import('../views/ConjugationQuiz.vue')
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue')
import WordData from '../assets/Words.json'
import VerbData from '../assets/Verb500.json'
import ConjugationData from '../assets/Conjugation.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'フランス語クイズ by Monsieur Langue',
      metaTags: [
        {
          name: 'description',
          content: 'フランス語の単語／動詞／動詞活用をゲーム感覚で学べるフランス語初心者～中級者向けの完全無料のフランス語クイズアプリです。'
        },
        {
          property: 'og:description',
          content: 'フランス語の単語／動詞／動詞活用をゲーム感覚で学べるフランス語初心者～中級者向けの完全無料のフランス語クイズアプリです。'
        }
      ]
    }
  },
  {
    path: '/word/',
    name: 'wordTop',
    component: WordQuizTop,
    meta: {
      title: 'フランス語単語クイズ by Monsieur Langue',
      metaTags: [
        {
          name: 'description',
          content: 'フランス語の数字・曜日・月・色・家族・乗り物・場所・動物・果物などの基本単語を楽しく簡単に学べるフランス語単語クイズです。'
        },
        {
          property: 'og:description',
          content: 'フランス語の数字・曜日・月・色・家族・乗り物・場所・動物・果物などの基本単語を楽しく簡単に学べるフランス語単語クイズです。'
        }
      ]
    }
  },
  {
    path: '/word/:genre/',
    name: 'wordQuiz',
    component: WordQuiz,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/verb/',
    name: 'verbTop',
    component: VerbQuizTop,
    meta: {
      title: 'フランス語動詞クイズ by Monsieur Langue',
      metaTags: [
        {
          name: 'description',
          content: 'フランス語頻出動詞500個がレベル別に4択クイズで出題されるゲーム感覚で楽しく簡単に学べるフランス語動詞クイズです。'
        },
        {
          property: 'og:description',
          content: 'フランス語頻出動詞500個がレベル別に4択クイズで出題されるゲーム感覚で楽しく簡単に学べるフランス語動詞クイズです。'
        }
      ]
    }
  },
  {
    path: '/verb/:level/',
    name: 'verbQuiz',
    component: VerbQuiz,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/conjugation/',
    name: 'conjugationTop',
    component: ConjugationQuizTop,
    meta: {
      title: 'フランス語動詞活用クイズ by Monsieur Langue',
      metaTags: [
        {
          name: 'description',
          content: '不規則で煩雑なフランス語の動詞活用をゲーム感覚で何度でも繰り返し練習できる無料のフランス語動詞活用クイズです。'
        },
        {
          property: 'og:description',
          content: '不規則で煩雑なフランス語の動詞活用をゲーム感覚で何度でも繰り返し練習できる無料のフランス語動詞活用クイズです。'
        }
      ]
    }
  },
  {
    path: '/conjugation/:verb/',
    name: 'conjugationQuiz',
    component: ConjugationQuiz,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/privacy_policy/',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      title: '利用規約',
      metaTags: [
        {
          name: 'description',
          content: 'フランス語クイズ by Monsieur Langue の利用規約です。'
        },
        {
          property: 'og:description',
          content: 'フランス語クイズ by Monsieur Langue の利用規約です。'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
//  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

//  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
//  if(nearestWithTitle) {
//    document.title = nearestWithTitle.meta.title;
//  } else if(previousNearestWithMeta) {
//    document.title = previousNearestWithMeta.meta.title;
//  }

  // titleとdescriptionを設定
  var quizGenre = ''
  var quizGenreJP = ''
  var verbTense = ''
  var verbTenseJP = ''
  var description = ''
  if (Object.keys(to.params).length !== 0) {
    if (to.name === 'wordQuiz') {
      quizGenre = to.params.genre
      for (var i = 0; i < Object.keys(WordData.data).length; i++) {
        if (WordData.data[i].tag == quizGenre) {
          quizGenreJP = WordData.data[i].name
        }
      }

      description = 'フランス語の【' + quizGenreJP + '】の単語クイズが4択問題で出題される完全無料のフランス語クイズアプリです。'
      document.title = 'フランス語単語クイズ【' + quizGenreJP + '】'
    } else if (to.name === 'verbQuiz') {
      quizGenre = to.params.level
      for (var j = 0; j < Object.keys(VerbData.data).length; j++) {
        if (VerbData.data[j].tag == quizGenre) {
          quizGenreJP = VerbData.data[j].name
        }
      }

      description = 'フランス語頻出動詞の中から50個の動詞で構成された' + quizGenreJP + 'の動詞クイズが出題される4択クイズアプリです。'
      document.title = 'フランス語頻出動詞クイズ【' + quizGenreJP + '】'
    } else if (to.name === 'conjugationQuiz') {
      quizGenre = to.params.verb.split('-')[1]
      verbTense = to.params.verb.split('-')[0]
      for (var k = 0; k < Object.keys(ConjugationData.data).length; k++) {
        if (ConjugationData.data[k].tag == quizGenre) {
          quizGenreJP = ConjugationData.data[k].name
        }
      }
      for (var l = 0; l < Object.keys(ConjugationData.tenses).length; l++) {
        if (ConjugationData.tenses[l].tense == verbTense) {
          verbTenseJP = ConjugationData.tenses[l].tenseName
        }
      }

      description = 'フランス語の動詞' + quizGenreJP + 'の' + verbTenseJP + 'の活用を4択クイズで練習できるフランス語動詞活用クイズです。'
      document.title = 'フランス語動詞活用クイズ【' + verbTenseJP + quizGenreJP + '】'
    }
  } else {
    document.title = to.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      if (!tagDef[key]) {
        tag.setAttribute(key, description)
      } else {
        tag.setAttribute(key, tagDef[key])
      }
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
});

export default router
