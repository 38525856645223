<template>
  <div class="home">
    <v-app>
      <v-app-bar color="indigo-darken-1" prominent>
        <v-app-bar-nav-icon @click="drawer ? drawer = false : drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <a href="https://quiz.monsieurlangue.com/" style="text-decoration:none; color:white">
            フランス語クイズ
          </a>
        </v-toolbar-title>
      </v-app-bar>
      <!-- サイドメニュー -->
      <v-navigation-drawer v-model="drawer" absolute temporary style="position:fixed;">
        <v-list nav dense>
          <v-list-item class="font-weight-bold" href="/">
            <v-icon icon="$home" />
            Home
          </v-list-item>

          <!-- Contents -->
          <v-list-group value="Word">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon icon="$eiffelTower" />
                フランス語単語
              </v-list-item>
            </template>
            <v-list-item class="font-weight-bold" href="/word/" v-bind="props">
              フランス語単語クイズ
            </v-list-item>
            <v-list-item
              v-for="genre in Words.data"
              :key="genre"
              :value="genre.tag"
              :href="'/word/' + genre.tag + '/'"
              v-bind="props"
            >
              <v-icon icon="$baguette" />
              {{ genre.name }}
            </v-list-item>
          </v-list-group>

          <v-list-group value="Verb">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon icon="$eiffelTower" />
                フランス語動詞
              </v-list-item>
            </template>
            <v-list-item class="font-weight-bold" href="/verb/" v-bind="props">
              フランス語動詞クイズ
            </v-list-item>
            <v-list-item
              v-for="level in Verb500.data"
              :key="level"
              :value="level.tag"
              :href="'/verb/' + level.tag + '/'"
              v-bind="props"
            >
              <v-icon icon="$baguette" />
              {{ level.name }}
            </v-list-item>
          </v-list-group>

          <v-list-item href="/conjugation/">
            <v-icon icon="$eiffelTower" />
            フランス語動詞活用クイズ
          </v-list-item>

        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-row no-gutters justify="center">
          <!-- Google Adsense TOP -->
          <div id="adsTop" style="width:1000px; max-width:100%; text-align:center;" v-html="adsContentTop"></div>
        </v-row>

        <v-row no-gutters justify="center">
          <v-col cols="12" md="9">
            <router-view />
          </v-col>
        </v-row>

        <v-row no-gutters justify="center">
          <!-- Google Adsense BOTTOM -->
          <div id="adsBottom" style="width:1000px; max-width:100%;" v-html="adsContentBottom"></div>
        </v-row>
      </v-main>

      <!-- フッター -->
      <v-footer class="bg-red-darken-1">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="link in links"
            :key="link"
            color="white"
            variant="text"
            rounded="xl"
            :href="link.url"
            target="_blank"
            class="no-uppercase"
          >
          {{ link.urlname }}
          <v-icon icon="$openNew" class="ml-1" />
          </v-btn>

          <v-col class="text-center my-1" cols="12">
            <v-btn
              v-for="link in siteInfo"
              :key="link"
              color="white"
              variant="text"
              rounded="xl"
              :href="link.url"
              target="_blank"
              class="no-uppercase"
            >
            {{ link.urlname }}
            </v-btn>
          </v-col>

          <v-col class="text-center my-2" cols="12">
            Copyright © {{ new Date().getFullYear() }} <a href="https://monsieurlangue.com/" class="text-decoration-none" style="color:white" target="_blank">Monsieur Langue</a>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Words from './assets/Words.json'
import Verb500 from './assets/Verb500.json'

export default {
  name: 'App',
  data: () => ({
    Words,
    Verb500,
    drawer: false,
    adsContentTop: '',
    adsContentBottom: '',

    links: [
      {
        urlname: "フランス語単語集",
        url: "https://monsieurlangue.com/french-words/"
      },
      {
        urlname: "フランス語頻出動詞",
        url: "https://monsieurlangue.com/most-common-french-verbs/"
      },
      {
        urlname: "フランス語頻出単語",
        url: "https://monsieurlangue.com/most-common-french-words/"
      },
      {
        urlname: "フランス語動詞活用表PDF",
        url: "https://monsieurlangue.com/french-verb-conjugation-pdf/"
      },
      {
        urlname: "フランスの天気",
        url: "https://weather.monsieurlangue.com"
      }
    ],

    siteInfo: [
      {
        urlname: "利用規約",
        url: "https://quiz.monsieurlangue.com/privacy_policy/"
      },
      {
        urlname: "お問い合わせ",
        url: "https://monsieurlangue.com/contactmosieurlangue/"
      }
    ],
  }),

  mounted() {
    if (document.getElementById('divadsenseTop')) {
      this.adsContentTop = document.getElementById('divadsenseTop').innerHTML
    }
    if (document.getElementById('divadsenseBottom')) {
      this.adsContentBottom = document.getElementById('divadsenseBottom').innerHTML
    }
  }
}
</script>

<style>
.no-uppercase {
     text-transform: initial !important;
}
</style>
